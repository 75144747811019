import { Point } from '@turf/helpers';
import { Feature, Geometry, LineString, MultiPolygon, Polygon } from 'geojson';
import { ThunkAction } from 'redux-thunk';

import { AppInstallStatus } from '../store/app/app.reducer';

export type CoreoSingleGeometryType = 'Point' | 'Polygon' | 'LineString';
export type CoreoGeometryType = CoreoSingleGeometryType | 'MultiPoint' | 'MultiLineString' | 'MultiPolygon';

// This represents the set of types that can be an "active" geometry.
export type CoreoGeometry = Point | LineString | Polygon;

export interface CoreoUser {
  admin: boolean;
  displayName: string;
  email: string;
  exp: string;
  iat: string;
  imageUrl: string;
  iss: string;
  moderator: boolean;
  projectId: number;
  role: 'admin' | 'moderator' | 'user';
  userId: number;
  username: string;
};

export interface CoreoAppPageBlock {
  config: any;
  type: string;
  order: number
  mediaItems: CoreoMediaItem[];
  style: {
    style: 'primary' | 'secondary' | null;
    class: string;
    padding: boolean;
    sizes: {
      xs: number;
      sm: number;
      md: number;
      lg: number;
      xl: number;
    }
  };

}

export interface CoreoAppPage {
  id: number;
  title: string;
  icon: string;
  order: number;
  listed: boolean;
  blocks: CoreoAppPageBlock[];
  type: string;
  config: any;
  mediaItems: CoreoMediaItem[];
  updatedAt: string;
};

export interface CoreoAppState {
  stateId: number;
  name: string;
  color: string;
  sort: number;
  verified: boolean;
  default: boolean;
}

export type CoreoDataStyleBase = {
  color: string;
  contrastColor: string;
  labelAttributeId?: number;
  labelCollectionAttributeId?: number;
  styleAttributeId?: number;
}

export type CoreoDataLineStyle = CoreoDataStyleBase & {
  lineWidth: number;
}

export type CoreoDataPointStyle = CoreoDataStyleBase & {
  pointRadius: number;
  pointOpacity: number;
  pointBorderColor: string;
  pointBorderOpacity: number;
  pointBorderWidth: number;
}

export type CoreoDataPolygonStyle = CoreoDataStyleBase & {
  polygonOpacity: number;
  polygonBorderWidth: number;
  polygonBorderOpacity: number;
  polygonBorderColor: string;
}

export type CoreoDataStyle = CoreoDataLineStyle & CoreoDataPointStyle & CoreoDataPolygonStyle;

export interface CoreoCollectionItem {
  id: number;
  collectionId: number;
  key: string;
  value: string;
  data: any;
  sort: number;
  icon: string;
  color: string;
  geometry?: CoreoGeometry;
  // mediaItems: CoreoMediaItem[];
};
export type CoreoCollectionSortMode = 'alphabetical' | 'attribute' | 'manual';
export interface CoreoCollection {
  id: number;
  name: string;
  sortMode: CoreoCollectionSortMode;
  sortAttributeId: number;
  geometric: boolean;
  mapLayer: boolean;
  mapVisible: boolean;
  style: CoreoDataStyle;
  mapSort: number;
};

export interface CoreoAttributeConditionRule {
  path: string;

  /**
   * For `integer` and `float` fields.
   *
   * Compares the answer against `numberComparand`.
   * - `lt`: Less Than
   * - `lte`: Less Than or Equal
   * - `eq`: Equals
   * - `ne`: Not Equals
   * - `gt`: Greater Than
   * - `gte`: Greater Than or Equal
   */
  numberCondition?: 'lt' | 'lte' | 'eq' | 'ne' | 'gt' | 'gte' | 'answered' | 'unanswered';
  /** A number to compare against the answer */
  numberComparand?: number;

  /**
   * For `boolean` fields.
   *
   * Evaluates to `answer == isTrue`.
   */
  isTrue?: boolean;

  /**
   * For `text` fields.
   *
   * Compares the answer against `textComparand`.
   * `is` and `not` both perform a simple equality check.
   * `contains` and `notcontains` look for the presence of a substring (or lack of).
   * `matches` and `notmatches` test against a regular expression.
   */
  textCondition?: 'is' | 'not' | 'contains' | 'notcontains' | 'matches' | 'notmatches' | 'answered' | 'unanswered';
  /** A text or regex string to compare against the answer */
  textComparand?: string;

  /**
   * For `date` fields.
   *
   * Compares the answer against `dateComparand`.
   */
  dateCondition?: 'before' | 'after' | 'is' | 'not' | 'answered' | 'unanswered';
  /** A date/time string to compare against the answer */
  dateComparand?: string;

  /**
   * For `datetime` fields.
   *
   * Compares the answer against `datetimeComparand`.
   * `beforetime` and `aftertime` compare the time of day.
   */
  datetimeCondition?: 'before' | 'after' | 'beforetime' | 'aftertime' | 'answered' | 'unanswered';
  /** A datetime or time string to compare against the answer */
  datetimeComparand?: string;

  /**
   * For `select` fields.
   *
   * Compares the answer against `selectComparand`.
   */
  selectCondition?: 'is' | 'not' | 'answered' | 'unanswered' | 'contains' | 'notcontains';
  /** A collection key to compare against the answer */
  selectComparand?: string;
};

export interface CoreoAttributeConditions {
  any: boolean;
  rules: CoreoAttributeConditionRule[];
}

export const coreoAttributeTypes = [
  'geometry',
  'attachment',
  'media',
  'date',
  'datetime',
  'select',
  'multiselect',
  'text',
  'boolean',
  'integer',
  'email',
  'url',
  'coordinatetransform',
  'float'] as const;
export type CoreoAttributeType = typeof coreoAttributeTypes[number];

export const coreoAttributeQuestionTypes = [
  'mediaGallery',
  'child',
  'association',
  'thankyou',
  'expression',
  'text',
  'confirm',
  'geometry',
  'long',
  'short',
  'boolean',
  'modal',
  'geometryselect',
  'slider',
  'photo',
  'signature',
  'multi'
] as const;

export type CoreoAttributeQuestionType = typeof coreoAttributeQuestionTypes[number];

export interface CoreoAttribute {
  id: number;
  projectId: number;
  meta: any;
  uuid: string;
  order: number;
  path: string;
  type: CoreoAttributeType;
  label: string;
  collectionId: number;
  filterable: boolean;
  questionType: CoreoAttributeQuestionType;
  config: any;
  text: string;
  description: string;
  help: string;
  conditions: CoreoAttributeConditions;
  sectionIdx: number;
  surveyId: number;
  associatedSurveyId: number;
  required: boolean;
  visible: boolean;
  parentCollectionId: number;
}

export interface CoreoForm {
  id: number;
  name: string;
  title: string;
  thankyou: string;
  color: string;
  titleAttributeId: number;
  secondaryTitleAttributeId: number;
  visible: boolean;
  allowMemberUpdate: boolean;
  allowOwnRecordDelete: boolean;
  private: boolean;
  sort: number;
  style: CoreoDataStyle;
  mapSort: number;
  mapVisible: boolean;
  labelAttributeId: number;
  labelCollectionAttributeId: number;
  styleAttributeId: number;
}

export interface CoreoMediaItem {
  id: number;
  name: string;
  type: string;
  caption: string;
  url: string;
  size: number;
}

export interface CoreoMediaTag {
  name: string;
  items: CoreoMediaItem[];
}

export interface CoreoMapLayer {
  id: number;
  projectId: number;
  name: string;
  sort: number;
  sourceId: number;
  sourceType: 'records' | 'collections' | 'image' | 'geojson';
  type: string;
  paint: any;
  layout: any;
  filter: any;
  source: string;
  visible: boolean;
  bounds: number[];
  style: CoreoDataStyle;
}

// export interface CoreoMap {
//   id: number;
//   uuid: string;
//   name: string;
//   zoom: number;
//   lng: number;
//   lat: number;
//   base: string;
//   layers: CoreoMapLayer[];
// }

// export interface CoreoMapState extends CoreoMap {
//   enabled: boolean;
// }

export interface CoreoAppSummary {
  id: number;
  name: string;
  icon: string;
  description: string;
};

export interface CoreoApiAppSummary extends CoreoAppSummary {
  allowContributors: boolean;
}

export interface CoreoAppSummaryStatus extends CoreoAppSummary {
  status: AppInstallStatus;
};

export interface CoreoAppConfig extends CoreoAppSummary {
  css?: string;
  bounds: MultiPolygon;
  slug: string;
  welcomePageId?: number;
  hideUsernames: boolean;
  membership: CoreoProjectRole;
  features: CoreoProjectFeatures;
}

export interface CoreoApp extends CoreoAppConfig {
  pages: CoreoAppPage[];
  states: CoreoAppState[];
  collections: CoreoCollection[];
  attributes: CoreoAttribute[];
  forms: CoreoForm[];
  maps: CoreoMapLayer[];
}

export interface CoreoProjectPollResult {
  project: CoreoApp;
  freeTrialExpired: boolean;
}

export interface CoreoMedia {
  mimeType: string;
  fileLocation: string;
}
export interface CoreoRecordAttachment extends CoreoMedia {
  id: number;
  attributeId: number;
  url: string;
  sort: number;
  recordId?: number;
  size?: number;
}

export type CoreoAssociateMap = { [id: number]: CoreoRecord[] };

export enum CoreoRecordSyncStatus {
  SUBMITTED = 0,
  PENDING_UPDATE,
  DRAFT,
  PENDING_DELETE,
  PENDING_CREATE
};

export interface CoreoRecord {
  id: number;
  projectId: number;
  // TODO - should either have formId or surveyId
  surveyId: number;
  formId: number;
  state: number;
  syncState: CoreoRecordSyncStatus;
  origSyncState?: CoreoRecordSyncStatus;
  syncError?: string;

  geometry: Geometry;
  geometryCenter: Point;
  feature?: Feature<Geometry>;

  data: { [path: string]: string };
  associates: CoreoAssociateMap;
  attachments: CoreoRecordAttachment[];
  createdAt?: string;
  updatedAt?: string;
  timestamp?: number;
  deletedAt?: string;
  userId: number;
  parentId?: number;
  user?: {
    username: string;
  };
}

export enum CoreoPendingRecordState {
  PENDING = 1,
  UPLOADING,
  ERROR_NETWORK,
  ERROR_API,
  ERROR_UNKNOWN
}

// export enum CoreoPendingRecordError {
//   NETWORK = 1,
//   API,
//   UNKNOWN
// }

export interface CoreoPendingRecord {
  id: number;
  projectId: number;
  surveyId: number;
  updatedAt: string;
  state: CoreoPendingRecordState;
}

export interface CoreoRecordFilter {
  userId: number | null;
  states: number[];
  forms: number[];
  from: string | null;
  to: string | null;
  customFilters: CoreoRecordCustomFilter[];
  customOperator: 'and' | 'or' | 'not';
}

export interface CoreoRecordCustomFilter {
  id: string;
  attribute?: CoreoAttribute;
  operator?: CoreoRecordCustomFilterOperator;
  value?: any;
  formId?: number;
}

export type CoreoRecordCustomFilterOperatorId =
  | 'and'
  | 'not:and'
  | 'or'
  | 'not:or'
  | 'is:null'
  | 'not:null'
  | 'eq'
  | 'ne'
  | 'in'
  | 'notIn'
  | 'substring'
  | 'startsWith'
  | 'endsWith'
  | 'gt'
  | 'lt'
  | 'gte'
  | 'lte'
  | 'is:true'
  | 'is:false';
export interface CoreoRecordCustomFilterOperator {
  id: CoreoRecordCustomFilterOperatorId;
  input: 'select' | 'text' | 'number' | 'date' | null;
  label: string;
  multiple: boolean;
  unary: boolean;
}

export type CoreoProjectRole = 'admin' | 'moderator' | 'member';

export interface CoreoProjectFeatures {
  offlineMaps: boolean;
  bingMaps: boolean;
  dataDrivenStyling: boolean;
}

export type CoreoMapFeatureType = 'item' | 'record' | 'cluster' | 'custom';

export interface CoreoMapFeature {
  type: CoreoMapFeatureType;
  sourceId: number;
  layerId?: string;
  source: string;
  geometry: CoreoGeometry;
  properties: any;
  id: number;
}

interface MapFeatureRecordEvent {
  type: 'record';
  feature: CoreoRecord;
  mapFeature: CoreoMapFeature;
}

interface MapFeatureCollectionItemEvent {
  type: 'item';
  feature: CoreoCollectionItem;
  mapFeature: CoreoMapFeature;
}

interface MapFeatureCustomEvent {
  type: 'custom';
  feature: any;
  mapFeature: CoreoMapFeature;
}

export type MapFeatureEvent =
  | MapFeatureRecordEvent
  | MapFeatureCollectionItemEvent
  | MapFeatureCustomEvent;

export type Unthunk<T> = T extends (...args: infer A) => ThunkAction<infer R, any, any, any>
  ? (...args: A) => R
  : T;

export type ProjectMapInstanceType = 'records' | 'attribute';

export interface GeometrySelectorUserSettings {
  snapToBaseLayer: boolean;
  snapTolerance: number;
  simplificationAmount: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  showMetrics: boolean;
}
